<template lang="pug">
    #photo-confirmation.video-step-dialog.is-fullscreen
      .modal-text
        h1 {{ title }}
        img#photo(ref="photo" :class="{ 'rotate-image': rotateImage }")
      .modal-footer
        button.button.cancel-btn(@click="cancel")
          icon(data="@icon/replay.svg")
          span Retake
        button.button.action-btn(@click="performAction")
          icon(data="@icon/check.svg")
          span Confirm
  </template>

<script>
export default {
  props: {
    title: { type: String, required: false, default: 'Verify Photo' },
    rotateImage: { type: Boolean, required: false, default: false },
    onComplete: { type: Function, required: true },
    onCancel: { type: Function, required: true },
  },

  data() {
    return {}
  },

  mounted() {
    const imgSrc = localStorage.getItem('capturedImage')
    this.$refs.photo.src = imgSrc
  },

  computed: {},

  watch: {},

  methods: {
    performAction,
    cancel,
    cleanUp,
  },

  components: {},
}

/* Computed ---------------------------------------------------- */
/* Watch ------------------------------------------------------- */
/* Methods ----------------------------------------------------- */
async function performAction() {
  // TODO: send photo to MedPlum, then delete from localstorage
  this.onComplete()
  this.cleanUp()
}

function cancel() {
  this.onCancel()
  this.cleanUp()
}

function cleanUp() {
  localStorage.removeItem('capturedImage')
  this.$modal.close('PhotoConfirmation')
}
</script>
